import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  brandColours,
  brandFonts,
  fontWeights,
  fontSize,
  standardTransition,
} from '../styles';
import { Container, Link } from './ui';

const StyledFeaturedCategories = styled.section`
  background-color: ${brandColours.tertiary};
`;

const StyledContainer = styled(Container)`
  padding-right: 0;
  padding-left: 0;
`;

const StyledInner = styled.div`
  ${maxBreakpointQuery.smedium`
    display: flex;
    flex-direction: column-reverse;
  `}

  ${minBreakpointQuery.smedium`
    display: grid;
    grid-template-columns: 1fr 1.5fr;
  `}
`;

const StyledContent = styled.div`
  padding: 30px 20px;

  ${minBreakpointQuery.small`
    padding-top: 40px;
    padding-bottom: 40px;
  `}

  ${minBreakpointQuery.smedium`
    padding: 50px;
  `}

  ${minBreakpointQuery.large`
    padding: 60px 80px;
  `}

  ${minBreakpointQuery.xlarge`
    padding-right: 100px;
    padding-bottom: 100px;
  `}

  ${minBreakpointQuery.xxlarge`
    padding: 80px 140px;
  `}

  ${minBreakpointQuery.xxxlarge`
    padding: 120px 180px;
  `}

  ${minBreakpointQuery.xxxxlarge`
    padding: 160px 220px;
  `}
`;

const StyledHeading = styled.h2`
  margin-bottom: 16px;
  color: ${brandColours.primary};
  font-family: ${brandFonts.secondary};
  font-weight: ${fontWeights.extrabold};
  ${fontSize(18)};

  ${minBreakpointQuery.small`
    margin-bottom: 20px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 24px;
  `}
`;

const StyledItems = styled.ul``;

const StyledItem = styled.li`
  margin-top: 10px;

  ${minBreakpointQuery.large`
    margin-top: 12px;
  `}

  ${minBreakpointQuery.xxlarge`
    margin-top: 14px;
  `}

  &:first-child {
    margin-top: 0;
  }
`;

const StyledLink = styled(Link)`
  position: relative;
  display: block;
  color: ${brandColours.quaternary};
  font-weight: ${fontWeights.semibold};
  ${fontSize(30)};
  transition: ${standardTransition('color')}, ${standardTransition('transform')};

  ${minBreakpointQuery.large`
    ${fontSize(33)};
  `}

  ${minBreakpointQuery.xxlarge`
    ${fontSize(36)};
  `}

  ${minBreakpointQuery.xxxxlarge`
    ${fontSize(39)};
  `}

  &:before {
    content: '⟶';
    position: absolute;
    top: 50%;
    left: 0;
    color: ${brandColours.secondary};
    font-weight: ${fontWeights.bold};
    ${fontSize(24)};
    line-height: 1;
    opacity: 0;
    transform: translate(calc(-100% - 6px), calc(-50% - 5px));
    transition: ${standardTransition('opacity')};

    ${minBreakpointQuery.large`
      ${fontSize(26)};
      transform: translate(calc(-100% - 8px), calc(-50% - 5px));
    `}

    ${minBreakpointQuery.xxlarge`
      ${fontSize(28)};
      transform: translate(calc(-100% - 10px), calc(-50% - 5px));
    `}

    ${minBreakpointQuery.xxxxlarge`
      ${fontSize(30)};
      transform: translate(calc(-100% - 12px), calc(-50% - 5px));
    `}
  }

  &:hover {
    color: ${brandColours.secondary};
    transform: translateX(32px);

    ${minBreakpointQuery.large`
      transform: translateX(36px);
    `}

    ${minBreakpointQuery.xxlarge`
      transform: translateX(42px);
    `}

    ${minBreakpointQuery.xxxxlarge`
      transform: translateX(44px);
    `}

    &:before {
      opacity: 1;
    }
  }
`;

const StyledImageWrapper = styled.div`
  ${minBreakpointQuery.smedium`
    position: relative;
  `}
`;

const StyledImage = styled(GatsbyImage)`
  ${maxBreakpointQuery.smedium`
    height: 300px;
  `}

  ${minBreakpointQuery.smedium`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  `}
`;

const FeaturedCategories = ({ heading, items }) => {
  const [image, setImage] = useState(items[0].image);

  return (
    <StyledFeaturedCategories>
      <StyledContainer wide={true}>
        <StyledInner>
          <StyledContent>
            <StyledHeading>{heading}</StyledHeading>
            <StyledItems>
              {items.map(({ id, productCategory, image, text }) => (
                <StyledItem key={id}>
                  <StyledLink
                    to={productCategory}
                    onMouseOver={() => setImage(image)}
                  >
                    {text || productCategory.title}
                  </StyledLink>
                </StyledItem>
              ))}
            </StyledItems>
          </StyledContent>
          <StyledImageWrapper>
            <StyledImage image={image.gatsbyImageData} alt={image.alt} />
          </StyledImageWrapper>
        </StyledInner>
      </StyledContainer>
    </StyledFeaturedCategories>
  );
};

export default FeaturedCategories;
