import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import ImageBanner from '../components/ImageBanner';
import FeaturedLogos from '../components/FeaturedLogos';
import ImageLogosContent from '../components/ImageLogosContent';
import FeaturedCategories from '../components/FeaturedCategories';
import FeaturedProducts from '../components/FeaturedProducts';
import ModularBlocks from '../components/ModularBlocks';
import { getProductCards } from '../utils';

const HomePageTemplate = ({
  data: { datoCmsHome, featuredProductsDatoCms, featuredProductsShopify },
}) => {
  const {
    locale,
    slugLocales,
    seoMetaTags,
    title,
    bannerImage,
    mobileBannerImage,
    bannerText,
    bannerLink,
    featuredLogos,
    introImage,
    introLogos,
    introLink,
    featuredCategoriesHeading,
    featuredCategories,
    featuredProductsHeading,
    featuredProductsLink,
    modularBlocks,
  } = datoCmsHome;

  const featuredProducts = getProductCards(
    featuredProductsDatoCms.nodes,
    featuredProductsShopify.nodes,
    locale
  );

  return (
    <Layout
      seo={seoMetaTags}
      locale={locale}
      slugLocales={slugLocales}
      page={datoCmsHome}
    >
      <main>
        <ImageBanner
          image={bannerImage}
          mobileImage={mobileBannerImage}
          text={bannerText}
          link={bannerLink}
          isHomePage={true}
        />
        <FeaturedLogos items={featuredLogos} isHomePage={true} />
        <ImageLogosContent
          heading={title}
          image={introImage}
          logos={introLogos}
          link={introLink}
        />
        <FeaturedCategories
          heading={featuredCategoriesHeading}
          items={featuredCategories}
        />
        <FeaturedProducts
          heading={featuredProductsHeading}
          link={featuredProductsLink}
          items={featuredProducts}
        />
        <ModularBlocks items={modularBlocks} locale={locale} />
      </main>
    </Layout>
  );
};

export const HomePageTemplateQuery = graphql`
  query HomePageTemplateQuery(
    $id: String!
    $locale: String!
    $featuredProducts: [String]!
  ) {
    datoCmsHome(id: { eq: $id }) {
      locale
      slugLocales: _allSlugLocales {
        locale
        value
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      bannerImage {
        ...HomeDesktopImageBannerFragment
      }
      mobileBannerImage {
        ...HomeMobileImageBannerFragment
      }
      bannerText
      bannerLink {
        text
        page {
          ...LinkFragment
        }
      }
      featuredLogos {
        url
        alt
      }
      introImage {
        gatsbyImageData(width: 1200, height: 750)
        alt
      }
      introLogos {
        url
        alt
      }
      introLink {
        text
        page {
          ...LinkFragment
        }
      }
      featuredCategoriesHeading
      featuredCategories {
        productCategory {
          title
          ...LinkFragment
        }
        image {
          gatsbyImageData(width: 1200, height: 750)
          alt
        }
        text
      }
      featuredProductsHeading
      featuredProductsLink {
        text
        page {
          ...LinkFragment
        }
      }
      modularBlocks {
        ...BackgroundImageCtaModularBlockFragment
        ...ContentModularBlockFragment
        ...FeaturedCustomerPhotosModularBlockFragment
        ...IconContentBlocksModularBlockFragment
        ...ImageContentModularBlockV1Fragment
        ...ImageContentModularBlockV2Fragment
        ...TestimonialsModularBlockFragment
      }
      ...LinkFragment
    }
    featuredProductsDatoCms: allDatoCmsProduct(
      filter: {
        locale: { eq: $locale }
        shopifyProduct: { in: $featuredProducts }
      }
    ) {
      nodes {
        ...DatoCmsProductCardFragment
        featuredProductCardImageOne: productCardImageOne {
          gatsbyImageData(width: 400, height: 490)
          alt
        }
        featuredProductCardImageTwo: productCardImageTwo {
          gatsbyImageData(width: 400, height: 490)
          alt
        }
      }
    }
    featuredProductsShopify: allShopifyProduct(
      filter: { handle: { in: $featuredProducts } }
    ) {
      nodes {
        ...ShopifyProductCardFragment
        featuredProductImage: featuredImage {
          gatsbyImageData(width: 400, height: 490)
          alt: altText
        }
      }
    }
  }
`;

export default HomePageTemplate;
