import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  standardColours,
  brandColours,
  headingStyles,
} from '../styles';
import { Container, Button } from './ui';

const StyledImageLogosContent = styled.section``;

const StyledContainer = styled(Container)`
  padding-right: 0;
  padding-left: 0;
`;

const StyledInner = styled.div`
  ${maxBreakpointQuery.smedium`
    display: flex;
    flex-direction: column-reverse;
  `}

  ${minBreakpointQuery.smedium`
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    align-items: center;
  `}
`;

const StyledImageLogos = styled.div`
  position: relative;

  ${minBreakpointQuery.smedium`
    height: 100%;
  `}
`;

const StyledLogos = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px;
  width: 100%;
  background: linear-gradient(
    to bottom,
    ${standardColours.transparentBlack(0)} 0%,
    ${standardColours.transparentBlack(0.6)} 100%
  );

  ${minBreakpointQuery.small`
    padding: 15px;
  `}

  ${minBreakpointQuery.large`
    padding: 20px;
  `}
`;

const StyledLogo = styled.img`
  padding: 10px;
  max-width: 60px;

  ${minBreakpointQuery.small`
    padding: 15px;
    max-width: 80px;
  `}

  ${minBreakpointQuery.large`
    padding: 20px;
    max-width: 100px;
  `}
`;

const StyledImage = styled(GatsbyImage)`
  ${maxBreakpointQuery.smedium`
    min-height: 380px;
  `}

  ${minBreakpointQuery.smedium`
    height: 100%;
  `}
`;

const StyledContent = styled.div`
  padding: 40px 20px;

  ${minBreakpointQuery.small`
    padding-top: 50px;
    padding-bottom: 50px;
  `}

  ${minBreakpointQuery.smedium`
    padding: 50px;
  `}

  ${minBreakpointQuery.large`
    padding-right: 80px;
    padding-left: 80px;
  `}

  ${minBreakpointQuery.xxlarge`
    padding-right: 100px;
    padding-left: 100px;
  `}

  ${minBreakpointQuery.xxxxlarge`
    padding-right: 120px;
    padding-left: 120px;
  `}
`;

const StyledHeading = styled.h1`
  ${headingStyles()};
  color: ${brandColours.primary};
`;

const StyledButton = styled(Button)`
  margin-top: 16px;
  padding-right: 40px;
  padding-left: 40px;
  color: ${brandColours.primary};
  border-color: ${brandColours.primary};

  ${minBreakpointQuery.small`
    margin-top: 20px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 24px;
  `}

  &:hover {
    color: ${standardColours.white};
    background-color: ${brandColours.primary};
  }
`;

const ImageLogosContent = ({ heading, image, logos, link }) => (
  <StyledImageLogosContent>
    <StyledContainer wide={true}>
      <StyledInner>
        <StyledImageLogos>
          <StyledImage image={image.gatsbyImageData} alt={image.alt} />
          {logos.length > 0 && (
            <StyledLogos>
              {logos.map(({ url, alt }, id) => (
                <StyledLogo key={id} src={url} alt={alt} loading="lazy" />
              ))}
            </StyledLogos>
          )}
        </StyledImageLogos>
        <StyledContent>
          <StyledHeading>{heading}</StyledHeading>
          <StyledButton to={link.page} alt={true}>
            {link.text}
          </StyledButton>
        </StyledContent>
      </StyledInner>
    </StyledContainer>
  </StyledImageLogosContent>
);

export default ImageLogosContent;
