import React, { useState, useEffect } from 'react';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  sectionMargins,
  standardColours,
  brandColours,
  brandFonts,
  fontSize,
} from '../styles';
import { Container, Button, PreviousArrow, NextArrow } from './ui';
import ProductCard from './ProductCard';

const StyledFeaturedProducts = styled.section`
  ${sectionMargins('50px', '120px')};
  overflow: hidden;

  .keen-slider {
    overflow: visible;
  }
`;

const StyledContainer = styled(Container)`
  ${maxBreakpointQuery.smedium`
    padding-right: 30px;
  `}
`;

const StyledInner = styled.div`
  ${minBreakpointQuery.small`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  `}
`;

const StyledHeading = styled.h2`
  color: ${brandColours.primary};
  font-family: ${brandFonts.secondary};
  ${fontSize(36)};

  ${minBreakpointQuery.small`
    order: 1;
  `}

  ${minBreakpointQuery.medium`
    ${fontSize(39)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(42)};
  `}
`;

const StyledButtonArrows = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${maxBreakpointQuery.small`
    margin-top: 20px;
    flex-direction: column;
  `}

  ${minBreakpointQuery.small`
    order: 2;
    margin-left: 20px;
  `}
`;

const StyledButton = styled(Button)`
  color: ${brandColours.primary};
  border-color: ${brandColours.primary};

  &:hover {
    color: ${standardColours.white};
    background-color: ${brandColours.primary};
  }
`;

const StyledArrows = styled.div`
  display: flex;

  ${maxBreakpointQuery.small`
    margin-top: 20px;
  `}

  ${minBreakpointQuery.small`
    margin-left: 20px;
  `}
`;

const StyledItems = styled.div`
  margin-top: 20px;

  ${minBreakpointQuery.tiny`
    margin-top: 30px;
  `}

  ${minBreakpointQuery.small`
    order: 3;
    margin-top: 40px;
  `}

  ${minBreakpointQuery.medium`
    margin-top: 50px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 60px;
  `}

  ${minBreakpointQuery.xxlarge`
    margin-top: 70px;
  `} 

  ${minBreakpointQuery.xxxxlarge`
    margin-top: 80px;
  `}
`;

const FeaturedProducts = ({ heading, link, items }) => {
  const [options, setOptions] = useState({});
  const [sliderRef, instanceRef] = useKeenSlider(options);

  useEffect(() => {
    setOptions({
      slides: { perView: 1, spacing: 10 },
      breakpoints: {
        '(min-width: 420px)': {
          slides: { perView: 2, spacing: 10 },
        },
        '(min-width: 768px)': {
          slides: { perView: 3, spacing: 15 },
        },
      },
    });
  }, []);

  return (
    <StyledFeaturedProducts>
      <StyledContainer>
        <StyledInner>
          <StyledHeading>{heading}</StyledHeading>
          <StyledItems ref={sliderRef} className="keen-slider">
            {items.map(item => (
              <ProductCard
                key={item.id}
                id={item.id}
                title={item.title}
                page={item}
                featuredImage={
                  item.featuredProductImage
                    ? item.featuredProductImage
                    : item.featuredImage
                }
                cardImages={{
                  cardImageOne: item.cardImageOne,
                  cardImageTwo: item.cardImageTwo,
                }}
                description={item.description}
                metafields={item.metafields}
                variants={item.variants}
                locale={item.locale}
                isFeaturedProducts={true}
                className="keen-slider__slide"
              />
            ))}
          </StyledItems>
          <StyledButtonArrows>
            <StyledButton to={link.page} alt={true}>
              {link.text}
            </StyledButton>
            <StyledArrows>
              <PreviousArrow onClick={() => instanceRef.current.prev()}>
                <span>Previous</span>
              </PreviousArrow>
              <NextArrow alt={true} onClick={() => instanceRef.current.next()}>
                <span>Next</span>
              </NextArrow>
            </StyledArrows>
          </StyledButtonArrows>
        </StyledInner>
      </StyledContainer>
    </StyledFeaturedProducts>
  );
};

export default FeaturedProducts;
